/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralSettingsDto,
  GeneralSettingsDtoListResponseModel,
  GeneralSettingsDtoModelResponse,
  GeneralStatus,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class GeneralSettings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags GeneralSettings
   * @name PostGeneralSettings
   * @request POST:/GeneralSettings/Add
   */
  postGeneralSettings = (data: GeneralSettingsDto, params: RequestParams = {}) =>
    this.request<GeneralSettingsDtoModelResponse, any>({
      path: `/GeneralSettings/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GeneralSettings
   * @name LoadList
   * @request GET:/GeneralSettings/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GeneralSettingsDtoListResponseModel, any>({
      path: `/GeneralSettings/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GeneralSettings
   * @name UpdateCreate
   * @request POST:/GeneralSettings/Update
   */
  updateCreate = (data: GeneralSettingsDto, params: RequestParams = {}) =>
    this.request<GeneralSettingsDtoModelResponse, any>({
      path: `/GeneralSettings/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
